<template>
  <div>
    <van-nav-bar
      :title="$t('common.xieyi')"
      left-arrow
      @click-left="onClickLeft"
      class="header"
      :fixed="true"
    />
    <van-loading type="spinner" v-if="loading" style="margin-top:4rem" />
    <div v-else class="mainContent" v-html="agreement.content"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      agreement: {
        name: '',
        content: ''
      },
      // 加载中
      loading: true
    }
  },
  created() {
    this.getinfo()
  },
  methods: {
    // 返回按钮
    onClickLeft() {
      this.$router.go(-1)
    },
    async getinfo() {
      const { data } = await this.$http.get('/home/index/agreement')
      if (data) {
        if (data.code === 200) {
          this.agreement = data.data
          this.loading = false
        } else {
          this.$toast(this.$t('common.fail'))
        }
      }
    }
  }
}
</script>
<style lang="less" scoped>
.mainContent {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  font-size: 0.93333rem;
  margin-top: 56px;
  padding: 15px;
}
</style>
